import React, { useState, useEffect, useContext, useMemo } from 'react';

const SizeContext = React.createContext({
  width: 0,
  height: 0,
});

const _window = typeof window !== 'undefined' ? window : null;

function WindowSizeProvider({ children }) {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (!_window) {
      return null;
    }

    setWidth(_window.innerWidth);
    setHeight(_window.innerHeight);

    const updateSize = () => {
      setWidth(_window.innerWidth);
      setHeight(_window.innerHeight);
    };

    _window.addEventListener('resize', updateSize);
    return () => _window.removeEventListener('resize', updateSize);
  }, []);

  const size = useMemo(
    () => ({
      width,
      height,
    }),
    [width, height]
  );
  return <SizeContext.Provider value={size}>{children}</SizeContext.Provider>;
}

function useSize() {
  return useContext(SizeContext);
}

export { WindowSizeProvider, useSize };
